export const MenuList = [
  {
    id: "1",
    name: "首页",
    path: "/home",
  },
  {
    id: "2",
    name: "资质",
    path: "/qualification",
  },
  {
    id: "3",
    name: "简介",
    path: "/introduction",
  },
  {
    id: "4",
    name: "案例",
    path: "/case",
  },
  {
    id: "5",
    name: "联系我们",
    path: "/contact",
  },
  {
    id: "6",
    name: "189-0158-9597",
    path: "/contact",
  },
];

//顶部距离
export const TopDistance = "100px";
