<!--  -->
<template>
  <div class="nav-bar">
    <div class="nav-bar__logo">
      <img src="../../assets/logo.png" alt="" />
    </div>
    <div class="nav-bar__menu">
      <div
        v-for="(item, index) in MenuList"
        :key="index"
        class="item"
        :class="{ fixItem: isFixId(item.id) }"
        @click="handleSelect(item)"
      >
        <img
          v-if="isFixId(item.id)"
          src="https://chuanggao-1309899583.cos.ap-nanjing.myqcloud.com/assets/phone.png"
          alt=""
        />
        {{ item.name }}
        <!-- 激活的效果 -->
        <div v-if="index === activeIndex" class="active-item"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { MenuList } from "@/const";

const router = useRouter();
let activeIndex = ref(1);

onMounted(() => {
  //获取当前路由
  let path = router.currentRoute.value.path;
  console.log(path);
  if (path === "/") {
    path = "/home";
  }
  //获取当前路由的下标
  let index = MenuList.findIndex((item) => item.path === path);
  activeIndex.value = index;
});

//选中导航栏
const handleSelect = (item) => {
  if (isFixId(item.id)) return;
  activeIndex.value = Number(item.id) - 1; //选中的导航栏
  router.push(item.path);
};

const isFixId = (id) => {
  return id === "6";
};
</script>
<style lang="less" scoped>
.nav-bar {
  width: 100%;
  height: 160px;
  padding: 50px 0px 30px 100px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ffffff;
  position: absolute;
  z-index: 100;
  left: 0px;
  background: linear-gradient(180deg, #1a7bdcff 0%, #40a6c800 100%);
  .nav-bar__logo {
    img {
      width: 80px;
      height: 80px;
    }
    margin-right: 10%;
  }
  .nav-bar__menu {
    display: flex;
    .item {
      width: 116px;
      text-align: center;
      margin-right: 4vw;
      height: 38px;
      line-height: 38px;
      font-size: 26px;
      font-family: "Source Han Sans CN";
      font-weight: 400;
      color: #ffffff;
      &:hover {
        cursor: pointer;
      }
      .active-item {
        position: absolute;
        top: 155px;
        width: 116px;
        height: 4px;
        background: #ffffff;
      }
    }
    .fixItem {
      width: 300px;
      display: flex;
      align-items: center;
      img {
        width: 50px;
        height: 50px;
        margin-right: 26px;
      }
    }
  }
}
</style>
