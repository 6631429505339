<!--
 * @Author: WIN-QV0R9JHOTKJ\Administrator 123
 * @Date: 2023-11-06 21:14:08
 * @LastEditors: DESKTOP-DO9B8F8\admin 297138663@qq.com
 * @LastEditTime: 2023-12-17 17:29:53
 * @FilePath: \chuanggao\chuanggao\src\components\Swiper\index.vue
 * @Description: 
-->
<!--  -->
<template>
  <div class="swiper">
    <el-carousel :height="screenWidth">
      <el-carousel-item v-for="item in swiperList" :key="item">
        <img :src="item" alt="" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import swiper1 from "@/assets/images/banner1.png";
import swiper2 from "@/assets/images/banner2.png";
import swiper3 from "@/assets/images/banner3.png";
import swiper4 from "@/assets/images/banner4.png";
import swiper5 from "@/assets/images/banner5.png";
let swiperList = [swiper1, swiper2, swiper3, swiper4, swiper5];
let screenWidth = ref("");

onMounted(() => {
  screenWidth.value =
    (window.innerWidth || document.documentElement.clientWidth) * 0.47 + "px";
  //监听窗口变化
  window.addEventListener("resize", () => {
    //获取当前屏幕宽度
    screenWidth.value =
      (window.innerWidth || document.documentElement.clientWidth) * 0.47 + "px";
  });
});

//获取当前屏幕宽度
</script>
<style lang="less" scoped>
.swiper {
  width: 100%;
  height: v-bind(screenWidth);
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
