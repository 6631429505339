import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  //home
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/index')
  },
  {
    path: '/qualification',
    name: 'qualification',
    component: () => import(/* webpackChunkName: "qualification" */ '@/views/qualification/index')
  },
  //简介
  {
    path: '/introduction',
    name: 'introduction',
    component: () => import(/* webpackChunkName: "introduction" */ '@/views/introduction/index')
  },
  //案例
  {
    path: '/case',
    name: 'case',
    component: () => import(/* webpackChunkName: "case" */ '@/views/case/index')
  },
  //联系我们
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '@/views/contact/index')
  },
 
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
